import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {EndpointShell, RecurringShell} from './Shell';
import IntlProviderUrlConnected from '../contexts/IntlProviderUrlConnected';
import {FetchKeyProvider} from '../contexts/FetchKey';
import Endpoint from './Endpoint';
import '../theme.scss';
import {ErrorBoundary} from 'react-error-boundary';
import Callback from './Callback';
import LogOut from './LogOut';
import Private from './Private';
import ActivateRecurring from './ActivateRecurring';
import ConfirmRecurring from './ConfirmRecurring';
import {PaymentOptionsRecurringCreate, PaymentOptionsRecurringUpdate} from './PaymentOptionsRecurring';
import Recurring from './Recurring';
import CancelRecurring from './CancelRecurring';
import Skeleton from './Skeleton';
import Fallback from './Fallback';
import Redirect from './Redirect';
import CookieConsent from './CookieConsent';
import ScrollState from './ScrollState';

const RAZZLE_APP_SERVICE_ID = process.env.RAZZLE_APP_SERVICE_ID;
const RAZZLE_APP_ENDPOINT_ID = process.env.RAZZLE_APP_ENDPOINT_ID;
const ENDPOINT_LINK = `/service/${RAZZLE_APP_SERVICE_ID}/endpoint/${RAZZLE_APP_ENDPOINT_ID}`;

const ApplicationRoutes = () => {
    return (
        <>
            <ScrollState/>
            <CookieConsent/>
            <React.Suspense fallback={<Skeleton/>}>
                <ErrorBoundary FallbackComponent={Fallback}>
                    <Routes>
                        <Route index element={<Redirect to={ENDPOINT_LINK} replace={true}/>}/>
                        <Route path='service/:serviceId/endpoint/:endpointId' element={<EndpointShell/>}>
                            <Route index element={<Endpoint/>}/>
                            {/* Private routes */}
                            <Route path='activate' element={<Private><ActivateRecurring/></Private>}/>
                            <Route path='confirm/:poolId' element={<Private><ConfirmRecurring/></Private>}/>
                            <Route path='payment-options/:currentPoolId' element={<Private><PaymentOptionsRecurringCreate/></Private>}/>
                        </Route>
                        <Route path='/recurring/:recurringId' element={<Private><RecurringShell/></Private>}>
                            <Route index element={<Recurring/>}/>
                            <Route path='cancel' element={<CancelRecurring/>}/>
                            <Route path='payment-options/:currentPoolId' element={<PaymentOptionsRecurringUpdate/>}/>
                        </Route>
                        <Route path='callback' element={<Callback/>}/>
                        <Route path='log-out' element={<LogOut/>}/>
                    </Routes>
                </ErrorBoundary>
            </React.Suspense>
        </>
    )
}

const Application = () => {
    return (
        <FetchKeyProvider>
            <IntlProviderUrlConnected>
                <ApplicationRoutes/>
            </IntlProviderUrlConnected>
        </FetchKeyProvider>
    )
}

export default React.memo(Application);
