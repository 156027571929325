import React from 'react';
import classNames from 'classnames';

import './PopUp.scss';

const PopUp = ({isVisible, children, fullscreen}) => {
    return (
        <div className={classNames('pop-up-container', {'pop-up-container--visible': isVisible})}>
            <div className={classNames('pop-up', {'pop-up--fullscreen-mobile': fullscreen === 'mobile'})} >
                {children}
            </div>
        </div>
    )
};

export default React.memo(PopUp);