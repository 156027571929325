import React from 'react';
import {useSearchParams} from 'react-router-dom';
import {IntlProvider} from 'react-intl';

import en from '../../compiled-lang/en';
import uk from '../../compiled-lang/uk';

function IntlProviderUrlConnected({children}) {
    const locales = {en, uk};
    const [queryParams] = useSearchParams();
    const locale = queryParams.get('locale') || 'uk';

    return (
        <IntlProvider locale={locale} defaultLocale='uk' messages={locales[locale]}>
            {children}
        </IntlProvider>
    );
};

export default React.memo(IntlProviderUrlConnected);
