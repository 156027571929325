/**
 * @generated SignedSource<<ac00e393e2f24c23e552b5b97638d211>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "FondySignResponse",
    "kind": "LinkedField",
    "name": "fondySignPooltick",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signature",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentMethodSignPooltickMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PaymentMethodSignPooltickMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "37f9845ee8f330579a6df105d6f0c326",
    "id": null,
    "metadata": {},
    "name": "PaymentMethodSignPooltickMutation",
    "operationKind": "mutation",
    "text": "mutation PaymentMethodSignPooltickMutation(\n  $request: FondySignRequestInput\n) {\n  fondySignPooltick(request: $request) {\n    signature\n  }\n}\n"
  }
};
})();

node.hash = "725a1b4ba3fa82967f6080e38b03572e";

module.exports = node;
