import 'isomorphic-fetch';
import Application from './components/Application';
import {BrowserRouter} from 'react-router-dom';
import React from 'react';
import {hydrateRoot} from 'react-dom/client';
import {RelayEnvironmentProvider} from 'react-relay/hooks';
import createRelayEnvironment from './utils/createRelayEnvironment';
import RelayClientSSR from 'react-relay-network-modern-ssr/lib/client';
import Cookies from 'js-cookie';
import SubscriptionProvider from './contexts/SubscriptionProvider';
import {Subscription} from './subscription';
import {AuthorizationProvider} from './contexts/Authorization';

const subscription = process.env.RAZZLE_APP_SUBSCRIPTION;

const store = {
    get: function(key) {return Cookies.get(key);},
    set: function(key, value) {Cookies.set(key, value, {expires: 365, path: '/'});},
    remove: function(key) {Cookies.remove(key, {path: '/'});},
};

hydrateRoot(document.getElementById('root'),
    <RelayEnvironmentProvider environment={createRelayEnvironment(
        () => ({'Content-Type': 'application/json'}), store
    )}>
        <BrowserRouter>
            <AuthorizationProvider {...{store}}>
                <SubscriptionProvider subscription={new Subscription(subscription, {reconnect: true, lazy: true})}>
                    <Application/>
                </SubscriptionProvider>
            </AuthorizationProvider>
        </BrowserRouter>
    </RelayEnvironmentProvider>
);

if (module.hot) {
    module.hot.accept();
}

