import React from 'react';
import Breadcrumbs from './Breadcrumbs';
import {FormattedMessage} from 'react-intl';
import {useLazyLoadQuery} from 'react-relay/hooks';
import UserMenu from './UserMenu';
import i18n from '../utils/i18n';
import {useParams} from 'react-router-dom';
import {RouterLinkButton} from './button/Button';
import PaymentMethod from './PaymentMethod';
import useQuery from '../utils/useQuery';
import Skeleton from './Skeleton';
import {graphqlEndpointByIdQuery, graphqlServiceByIdQuery} from '../graphql';
import getPeriodFromCron from '../utils/getPeriodFromCron';

const breadcrumbs = [{name: 'Subscription', nameUk: 'Підписка', route: '../',}, {name: 'Ordering', nameUk: 'Оформлення'}];
const RAZZLE_APP_DEMO_MERCHANT_ID = process.env.RAZZLE_APP_DEMO_MERCHANT_ID;

const ActivateRecurring = () => {
    const {endpointId, serviceId} = useParams();
    const {locale = 'uk'} = useQuery();
    const [pool, setPool] = React.useState(null);
    const setPoolCallback = React.useCallback((value) => {
        setPool(value);
    }, []);
    const {endpointById} = useLazyLoadQuery(graphqlEndpointByIdQuery, {endpointId: endpointId});
    const {serviceById} = useLazyLoadQuery(graphqlServiceByIdQuery, {serviceId: serviceId});
    const merchantId = serviceById.demo ? RAZZLE_APP_DEMO_MERCHANT_ID : serviceById.mrchntd;
    const cronPeriod = getPeriodFromCron(endpointById.cron);
    const [redirect, setRedirect] = React.useState(false);
    const setRedirectCallback = React.useCallback((value) => {
        setRedirect(value);
    });

    return (
        <>{redirect
            ? <Skeleton/>
            : <div className='view'>
                <div className='padding-bottom-2rem mw768-padding-top-2rem mw768-padding-bottom-4rem'>
                    <Breadcrumbs {...{breadcrumbs}}/>
                </div>
                <h1 className='color-gray-900 text-title semibold padding-bottom-2rem mw768-padding-bottom-3rem'>
                    <FormattedMessage defaultMessage='Subscribe'/>
                </h1>
                <div className='margin-bottom-1dot5rem mw768-margin-bottom-2rem'>
                    <UserMenu/>
                </div>
                <div className='mw768-flex-1 mw768-max-width-26dot5rem'>
                    <p className='text-xl semibold color-gray-900'>{i18n(endpointById, 'name', locale)}</p>
                    <p className='text-xl color-gray-900 margin-top-0dot25rem mw768-margin-top-0dot5rem'>
                        {endpointById.price}
                        {' UAH '}
                        {i18n(cronPeriod, 'title', locale)}
                    </p>
                </div>
                <div className='mw768-max-width-26dot5rem padding-top-1dot5rem mw768-padding-top-2rem'>
                    <PaymentMethod {...{setPool: setPoolCallback, pool, serviceId: serviceById.id, merchantId, setRedirect: setRedirectCallback}}/>
                </div>
                <div className='margin-top-2rem mw768-margin-top-3rem mw768-max-width-26dot5rem'>
                    <RouterLinkButton {...{size: 'lg', color: 'primary-dark', fluid: 'always', to: `../confirm/${pool}`, disabled: !pool}}>
                        <FormattedMessage defaultMessage='Continue'/>
                    </RouterLinkButton>
                </div>
            </div>
        }</>
    )
};

export default React.memo(ActivateRecurring);