import React from 'react';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import 'vanilla-cookieconsent/dist/cookieconsent';
import useQuery from '../utils/useQuery';
// import 'vanilla-cookieconsent/src/cookieconsent.css';
// import 'vanilla-cookieconsent/src/cookieconsent';

// in server.js for the scripts to be loaded on consent <script type='text/plain' data-cookiecategory='analytics'>...

const configuration = {
    page_scripts: true,
    onChange: () => {
        if (window.CookieConsentApi?.allowedCategory('analytics')) {
            typeof gtag === 'function' && gtag('consent', 'update', {
                'ad_storage': 'granted',
                'analytics_storage': 'granted'
            });
            typeof fbq === 'function' && fbq('consent', 'grant');
        } else {
            typeof gtag === 'function' && gtag('consent', 'update', {
                'ad_storage': 'denied',
                'analytics_storage': 'denied'
            });
            typeof fbq === 'function' && fbq('consent', 'revoke');
        }
    },
    languages: {
        'en': {
            consent_modal: {
                title: 'We use cookies!',
                description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">More</button>',
                primary_btn: {text: 'Accept all', role: 'accept_all'},
                secondary_btn: {text: 'Reject all', role: 'accept_necessary'}
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                blocks: [
                    {
                        title: 'Cookie usage 📢',
                        description: 'This site uses cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want.'
                    }, {
                        title: 'Strictly necessary cookies',
                        description: 'These cookies are essential for the proper functioning of website. Without these cookies, the website would not work properly',
                        toggle: {value: 'necessary', enabled: true, readonly: true}
                    }, {
                        title: 'Analytics cookies',
                        description: 'These cookies allow the website to remember the choices you have made in the past',
                        toggle: {value: 'analytics', enabled: false, readonly: false}
                    }
                ]
            }
        },
        'uk': {
            consent_modal: {
                title: 'Ми використовуємо файли cookie!',
                description: 'Вітаю, цей веб-сайт використовує файли cookie, щоб забезпечити правильну роботу, а також файли cookie для відстеження того, як ви з ним взаємодієте. Останні будуть встановлені лише після погодження. <button type="button" data-cc="c-settings" class="cc-link">Більше</button>',
                primary_btn: {
                    text: 'Прийняти все',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Відхилити все',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Налаштування файлів cookie',
                save_settings_btn: 'Зберегти налаштування',
                accept_all_btn: 'Прийняти все',
                reject_all_btn: 'Відхилити все',
                close_btn_label: 'Закрити',
                blocks: [
                    {
                        title: 'Використання файлів cookie 📢',
                        description: 'Цей сайт використовує файли cookie, щоб забезпечити основні функціональні можливості веб-сайту та покращити ваш віртуальний досвід. Ви можете вибрати чи дозволити використання файлів cookie для кожної категорії в будь-який момент.'
                    }, {
                        title: 'Строго необхідні файли cookie',
                        description: 'Ці файли cookie є необхідними для належного функціонування веб-сайту. Без цих файлів cookie сайт може не працювати належним чином.',
                        toggle: {value: 'necessary', enabled: true, readonly: true}
                    }, {
                        title: 'Файли cookie для аналітики.',
                        description: 'Ці файли cookie дозволяють сайту запам\'ятовувати вибори, які ви зробили раніше.',
                        toggle: {value: 'analytics', enabled: false, readonly: false}
                    }
                ]
            }
        }
    }
};

const CookieConsent = () => {
    const {locale = 'uk'} = useQuery();
    React.useEffect(() => {
        if (window.initCookieConsent && !document.getElementById('cc--main')) {
            window.CookieConsentApi = window.initCookieConsent();
            window.CookieConsentApi.run({
                ...configuration,
                current_lang: locale,
            });
        }
    }, [locale]);
    return null;
};

export default React.memo(CookieConsent);