import React from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import i18n from '../utils/i18n';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import Button, {RouterLinkButton} from './button/Button';
import PopUp from './pop-up/PopUp';
import XCloseIcon from '../assets/x-close.svg';
import UserCheckIcon from '../assets/user-check.svg';
import CreditCardCheckIcon from '../assets/credit-card-check.svg';
import ShieldCheckIcon from '../assets/shield-check.svg';
import {queryParameters} from '../utils/qs';
import searchUnFilter from '../utils/searchUnFilter';
import classNames from 'classnames';
import UserMenu from './UserMenu';
import useQuery from '../utils/useQuery';
import {useAuthorizationState} from '../contexts/Authorization';
import {graphqlEndpointByIdQuery} from '../graphql';
import getPeriodFromCron from '../utils/getPeriodFromCron';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;

const PopUpContent = React.memo(({setPopUpVisibility}) => {
    const location = useLocation();
    const qps = queryParameters(location);
    const signIn = (e) => {
        e.preventDefault();
        setTimeout(() => {
            window.location.href = RAZZLE_APP_ACCOUNTS + '/scope?scope=Users:.* Recurrings:.*&redirect=' + document.location.origin + '/callback&continue='
                + encodeURIComponent(location.pathname + '/activate' + searchUnFilter(document.location.search))
                + (qps.length ? '&' + qps.join('&') : '');
        }, 500);
    };

    return (
        <div className='display-flex flex-direction-column mw768-height-33rem height-100percent max-height-100vh mw768-max-height-auto'>
            <div className='text-align-right display-flex justify-content-flex-end padding-bottom-1dot25rem mw768-padding-bottom-1dot5rem'>
                <button className='display-block width-1dot5rem height-1dot5rem color-gray-500 cursor-pointer' onClick={() => {setPopUpVisibility(false)}}>
                    <XCloseIcon className='display-block'/>
                </button>
            </div>
            <p className='text-xl semibold color-gray-900 padding-bottom-1dot5rem mw768-padding-bottom-2rem'>
                <FormattedMessage defaultMessage='UAF has partnered with Ottry to provide convenient and secure recurring payments'/>
            </p>
            <div className='flex-1'>
                <div className='padding-bottom-1rem display-flex'>
                    <div className='width-1dot5rem height-1dot5rem color-gray-600'>
                        <UserCheckIcon className='display-block'/>
                    </div>
                    <p className='padding-left-0dot5rem flex-1 color-gray-700'>
                        <span>
                            <FormattedMessage defaultMessage='Log in to'/>
                            {' '}
                        </span>
                        <Button {...{plain: true, color: 'primary', size: 'lg', clickHandler: signIn}}>
                            Ottry Accounts
                        </Button>
                        <span>
                            {' '}
                            <FormattedMessage defaultMessage='for identification. Create a new account or log in to an existing one in the next step.'/>
                        </span>
                    </p>
                </div>
                <div className='padding-bottom-1rem display-flex'>
                <div className='width-1dot5rem height-1dot5rem color-gray-600'>
                        <CreditCardCheckIcon className='display-block'/>
                    </div>
                    <p className='padding-left-0dot5rem flex-1 color-gray-700'>
                        <FormattedMessage defaultMessage='Add a payment method and verify your bank card.'/>
                    </p>
                </div>
                <div className='display-flex'>
                <div className='width-1dot5rem height-1dot5rem color-gray-600'>
                        <ShieldCheckIcon className='display-block'/>
                    </div>
                    <p className='padding-left-0dot5rem flex-1 color-gray-700'>
                        <FormattedMessage defaultMessage='Your data is encrypted for your security.'/>
                    </p>
                </div>
            </div>
            <div className=''>
                <Button {...{color: 'primary-gradient', size: 'lg', fluid: 'always', clickHandler: signIn}}>
                    <FormattedMessage defaultMessage='Continue'/>
                </Button>
            </div>
        </div>
    )
});

const Endpoint = () => {
    const {endpointId} = useParams();
    const {locale = 'uk'} = useQuery();
    const [popUpVisible, setPopUpVisibility] = React.useState(false);
    const authorizationState = useAuthorizationState();
    const {endpointById} = useLazyLoadQuery(graphqlEndpointByIdQuery, {endpointId: endpointId});
    const cronPeriod = getPeriodFromCron(endpointById.cron);
    
    return (
        <div className='view'>
            <div className={classNames({'mw768-padding-top-7dot5rem': !!authorizationState, 'mw768-padding-top-8rem': !authorizationState})}>
                {authorizationState &&
                    <div className='margin-bottom-1dot5rem mw768-margin-bottom-2rem'>
                        <UserMenu/>
                    </div>
                }
                <div className='display-flex flex-direction-column mw768-flex-direction-row mw768-justify-content-space-between'>
                    <div className='mw768-flex-1 mw768-padding-right-2rem mw768-max-width-26dot5rem'>
                        <p className='text-xl semibold color-gray-900'>{i18n(endpointById, 'name', locale)}</p>
                        <p className='text-xl color-gray-900 margin-top-0dot25rem mw768-margin-top-0dot5rem'>
                            {endpointById.price}
                            {' UAH '}
                            {i18n(cronPeriod, 'title', locale)}
                        </p>
                        <p className='text-md color-gray-500 margin-top-1rem'>
                            <FormattedMessage defaultMessage='Funds will be debited after subscription.'/>
                            {' '}
                            <FormattedMessage defaultMessage='The next withdrawal will be made on'/>
                            {' '}
                            {moment(endpointById.tick).locale(locale).format('DD MMMM YYYY')}
                        </p>
                    </div>
                    <div className='margin-top-2rem mw768-margin-top-0 mw768-width-14dot375rem'>
                        {authorizationState &&
                            <div>
                                <RouterLinkButton {...{color: 'primary-dark', size: 'lg', fluid: 'always', to: 'activate'}}>
                                    <FormattedMessage defaultMessage='Subscribe'/>
                                </RouterLinkButton>
                            </div>
                        }
                        {!authorizationState &&
                           <>
                                <div>
                                    <Button {...{color: 'primary-dark', size: 'lg', fluid: 'always', clickHandler: () => {setPopUpVisibility(true)}}}>
                                        <FormattedMessage defaultMessage='Subscribe'/>
                                    </Button>
                                </div>
                                <div className='margin-top-0dot75rem'>
                                    <Button {...{color: 'secondary-gray', size: 'lg', fluid: 'always', clickHandler: () => {setPopUpVisibility(true)}}}>
                                        <FormattedMessage defaultMessage='Manage subscription'/>
                                    </Button>
                                </div>
                           </>
                        }
                    </div>
                </div>
            </div>
            <PopUp {...{isVisible: popUpVisible, fullscreen: 'mobile'}}>
                <PopUpContent {...{setPopUpVisibility}}/>
            </PopUp>
        </div>
    )
}

export default React.memo(Endpoint);