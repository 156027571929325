import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import Breadcrumbs from './Breadcrumbs';
import i18n from '../utils/i18n';
import Button, {RouterLinkButton} from './button/Button';
import useQuery from '../utils/useQuery';
import {FetchKeyStateContext} from '../contexts/FetchKey';
import getPeriodFromCron from '../utils/getPeriodFromCron';

const breadcrumbs = [
    {name: 'Subscription', nameUk: 'Підписка', route: '../'},
    {name: 'Cancel', nameUk: 'Скасувати'}
];

const CancelRecurring = () => {
    const {locale = 'uk'} = useQuery();
    const {recurringId} = useParams();
    const navigate = useNavigate();
    const fetchKeyState = React.useContext(FetchKeyStateContext);
    const {viewer} = useLazyLoadQuery(
        graphql`
            query CancelRecurringQuery($first: Int!, $after: String) {
                viewer {
                    recurringsByViewer(first: $first, after: $after) @connection(key: "CancelRecurring_recurringsByViewer") {
                        edges {
                            node {
                                id
                                tick
                                recurringtick {
                                    id
                                    status
                                    tick
                                }
                                pool {
                                    id
                                    cardPan
                                    cardType
                                }
                                recurringEndpoints {
                                    endpoint {
                                        id
                                        name
                                        nameUk
                                        price
                                        tick
                                        cron
                                    }
                                }
                                recurringService {
                                    service {
                                        id
                                        name
                                        nameUk
                                    }
                                }   
                            }
                        }
                    }
                }
            }
        `,
        {first: 100},
        {fetchKey: fetchKeyState}
    );

    const recurring = viewer.recurringsByViewer.edges.find(item => item.node.id === recurringId);
    const endpointById = recurring.node.recurringEndpoints[0].endpoint;
    const cronPeriod = getPeriodFromCron(endpointById.cron);

    const [pause, pausing] = useMutation(
        graphql`
            mutation CancelRecurringPauseMutation($request: RecurringPauseRequestInput) {
                pauseRecurring(request: $request) {
                    id
                    recurringtick {
                        id
                        status
                        tick
                    }
                    tick
                }
            }
    `);

    const cancelRecurring = () => {
        pause({
            variables: {
                request: {
                    recurring: recurringId
                }
            },
            onCompleted: () => {
                
                navigate(`..`);
            },
            onError: () => {}
        });
    };


    return (
        <div className='view'>
            <div className='padding-bottom-2rem mw768-padding-top-2rem mw768-padding-bottom-4rem'>
                <Breadcrumbs {...{breadcrumbs}}/>
            </div>
            <h1 className='color-gray-900 text-title semibold padding-bottom-2rem mw768-padding-bottom-3rem'>
                <FormattedMessage defaultMessage='Cancel subscription'/>
            </h1>
            <div className='mw768-flex-1 mw768-max-width-26dot5rem'>
                <p className='text-xl semibold color-gray-900'>{i18n(endpointById, 'name', locale)}</p>
                <p className='text-xl color-gray-900 margin-top-0dot25rem mw768-margin-top-0dot5rem'>
                    {endpointById.price}
                    {' UAH '}
                    {i18n(cronPeriod, 'title', locale)}
                </p>
                <p className='padding-top-1rem text-md color-gray-500'>
                    <FormattedMessage defaultMessage='The subscription will be cancelled, but you will be able to use it until the end of the billing period, which is until'/>
                    <span>{` ${moment(recurring.node.tick).locale(locale).format('DD MMMM YYYY')}`}</span>
                </p>
                <p className='padding-top-1rem text-md color-gray-500'>
                    <FormattedMessage defaultMessage='If you change your mind, you can renew your subscription.'/>
                </p>
                <div className='margin-top-2rem mw768-margin-top-3rem'>
                    <div className='margin-bottom-0dot75rem'>
                        <Button {...{size: 'lg', color: 'error', fluid: 'always', clickHandler: cancelRecurring, isLoading: pausing}}>
                            <FormattedMessage defaultMessage='Cancel subscription'/>
                        </Button>
                    </div>
                    <RouterLinkButton {...{size: 'lg', color: 'secondary-gray', fluid: 'always', to: '..'}}>
                        <FormattedMessage defaultMessage='Back'/>
                    </RouterLinkButton>
                </div>
            </div>
        </div>
    )
}

export default React.memo(CancelRecurring);