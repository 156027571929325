import React from 'react';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import PaymentsHistoryEmpty from '../assets/payments-history-empty.svg';
import Button from './button/Button';
import ChevronDownIcon from '../assets/chevron-down.svg';
import ChevronUpIcon from '../assets/chevron-up.svg';
import i18n from '../utils/i18n';
import DotsVerticalIcon from '../assets/dots-vertical.svg';
import classNames from 'classnames';
import CornerUpLeftIcon from '../assets/corner-up-left.svg';
import SlashCircleIcon from '../assets/slash-circle.svg';
import CheckIcon from '../assets/check.svg';
import {graphql, useLazyLoadQuery, usePaginationFragment, useFragment, useMutation} from 'react-relay/hooks';
import './PaymentHistory.scss';
import useQuery from '../utils/useQuery';
import {useParams} from 'react-router-dom';

const statusParams = {
    0: {
        icon: <CornerUpLeftIcon className='display-block width-0dot75rem height-0dot75rem color-gray-500 margin-right-0dot25rem'/>,
        badgeClasses: 'background-color-gray-100',
        textColor: 'color-gray-600',
        status: 'processing',
        statusUk: 'обробляється'
    },
    1: {
        icon: <CornerUpLeftIcon className='display-block width-0dot75rem height-0dot75rem color-gray-500 margin-right-0dot25rem'/>,
        badgeClasses: 'background-color-gray-100',
        textColor: 'color-gray-600',
        status: 'processing',
        statusUk: 'обробляється'
    },
    2: {
        icon: <CornerUpLeftIcon className='display-block width-0dot75rem height-0dot75rem color-gray-500 margin-right-0dot25rem'/>,
        badgeClasses: 'background-color-gray-100',
        textColor: 'color-gray-600',
        status: 'processing',
        statusUk: 'обробляється'
    },
    3: {
        icon: <CornerUpLeftIcon className='display-block width-0dot75rem height-0dot75rem color-gray-500 margin-right-0dot25rem'/>,
        badgeClasses: 'background-color-gray-100',
        textColor: 'color-gray-600',
        status: 'processing',
        statusUk: 'обробляється'
    },
    4: {
        icon: <CheckIcon className='display-block width-0dot75rem height-0dot75rem color-success-500 margin-right-0dot25rem'/>,
        badgeClasses: 'background-color-success-100',
        textColor: 'color-success-600',
        status: 'paid',
        statusUk: 'оплачено'
    },
    5: {
        icon: <SlashCircleIcon className='display-block width-0dot75rem height-0dot75rem color-error-500 margin-right-0dot25rem'/>,
        badgeClasses: 'background-color-error-100',
        textColor: 'color-error-600',
        status: 'failed',
        statusUk: 'відмова'
    },
    6: {
        icon: <SlashCircleIcon className='display-block width-0dot75rem height-0dot75rem color-error-500 margin-right-0dot25rem'/>,
        badgeClasses: 'background-color-error-100',
        textColor: 'color-error-600',
        status: 'failed',
        statusUk: 'відмова'
    },
    7: {
        icon: <CornerUpLeftIcon className='display-block width-0dot75rem height-0dot75rem color-gray-500 margin-right-0dot25rem'/>,
        badgeClasses: 'background-color-gray-100',
        textColor: 'color-gray-600',
        status: 'refunded',
        statusUk: 'повернуто'
    },
}

const PaymentsHistoryRow = React.memo(({item, updateHistory}) => {
    const {locale = 'uk'} = useQuery();
    const [dropDownVisible, setDropDownVisibility] = React.useState(false);
    const buttonRef = React.useRef();
    React.useEffect(() => {
        const onClickOutsideHandler = (event) => {
            if (dropDownVisible && !(
                buttonRef.current.contains(event.target)
            )) setDropDownVisibility(false);
        };
        document.addEventListener('click', onClickOutsideHandler, true);
        return () => {
            document.removeEventListener('click', onClickOutsideHandler, true);
        };
    }, [dropDownVisible, buttonRef]);
    const impulse = useFragment(
        graphql`
            fragment PaymentHistoryImpulse_impulse on Impulse {
                id
                status
                amount
                modified
                version
            }
        `,
        item
    );
    const [commit, isMutationInFlight] = useMutation(
        graphql`
            mutation PaymentHistoryRowMutation($request: TryRecurringImpulseRequestInput!) {
                tryRecurringImpulse(request: $request) {
                    id
                }
            }
    `);

    const retryPayment = () => {
        commit({variables: {
            request: {
                id: impulse.id,
                version: impulse.version
            }
        },
        onCompleted: (data) => {
            updateHistory();
        },
        onError: () => {}
        })
    }

    return (
        <>
            <div className='color-gray-700 medium text-md grid-col-1'>
                <p className='column-date'>{moment(impulse.modified).locale(locale).format('D MMM.')}</p>
            </div>
            <div className='grid-col-2 color-gray-700 text-md text-align-rigth'>{impulse.amount} UAH</div>
            <div className={`grid-col-3`}>
                <div className='column-badge'>
                    <div className={`display-flex align-items-center border-radius-1rem padding-right-0dot5rem padding-left-0dot375rem padding-top-0dot125rem padding-bottom-0dot125rem width-max-content ${statusParams[impulse.status].badgeClasses}`}>
                        {statusParams[impulse.status].icon}
                        <span className={`text-xs medium text-transform-capitalize ${statusParams[impulse.status].textColor}`}>{i18n(statusParams[impulse.status], 'status', locale)}</span>
                    </div>
                </div>
            </div>
            <div className='grid-col-4 position-relative'>
                <div ref={buttonRef}>
                    <DotsVerticalIcon className={classNames('display-block height-1dot25rem width-1dot25rem', {'color-gray-400': !dropDownVisible, 'color-gray-700': dropDownVisible})} onClick={() => setDropDownVisibility(true)}/>
                </div>
                <div className={classNames('shadow-lg display-none position-absolute width-max-content right-0 top-1dot75rem z-index-1 border-radius-0dot5rem border-1px-solid border-color-gray-100 background-color-white', {'display-block': dropDownVisible})}>
                    {statusParams[impulse.status].status === 'failed' &&
                        <div className='padding-left-1rem padding-right-1rem padding-top-0dot625rem padding-bottom-0dot625rem'>
                            <Button{...{color: 'secondary-gray', size: 'sm', plain: true, disabled: isMutationInFlight, clickHandler: retryPayment}}>
                                <span className='regular'><FormattedMessage defaultMessage='Retry payment'/></span>
                            </Button>
                        </div>
                    }
                </div>
            </div>
        </>
    )
});

const PaymentHistory = ({setFirstImpulseStatusFailed}) => {
    const {recurringId} = useParams();
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
        options: {fetchKey: 0},
        variables: {
            first: 3, recurring: recurringId, fetchKey: 0
        }
    });
    const [isPending, startTransition] = React.useTransition({timeoutMs: 5000});
    const refreshQueryOptions = React.useCallback(() => {
        startTransition(() => {
            setRefreshedQueryOptions(prev => ({
                ...prev,
                options: {
                    ...prev.options,
                    fetchKey: prev.options.fetchKey + 1
                },
                variables: {
                    ...prev.variables,
                    fetchKey: prev.variables.fetchKey + 1
                }
            }));
        });
    }, []); 
    const {viewer} = useLazyLoadQuery(
        graphql`
            query PaymentHistoryQuery($first: Int!, $after: String, $recurring: String, $status: [Int]) {
                viewer {
                    ...PaymentHistory_viewer
                }
            }
        `,
        refreshedQueryOptions.variables,
        refreshedQueryOptions.options
    );
    const {data, loadNext, hasNext, isLoadingNext} = usePaginationFragment(
        graphql`
            fragment PaymentHistory_viewer on Viewer @refetchable(queryName: "RecurringsImpulsesImplQuery") {
                impulsesByViewerAndRecurring(first: $first, after: $after, recurring: $recurring, status: $status) @connection(key: "Recurrings_impulsesByViewerAndRecurring") {
                    edges {
                        node {
                            id
                            status
                            ...PaymentHistoryImpulse_impulse
                        }
                    }
                }
            }
            
        `,
        viewer
    );
    const history = data.impulsesByViewerAndRecurring.edges;
    React.useEffect(() => {
        if (history[0] && (history[0].node.status === 5 || history[0].node.status === 6)) {
            setFirstImpulseStatusFailed();
        }
    }, [data]);

    return (
        <div>
            <div className='border-bottom-1px-solid border-color-gray-200 padding-top-1rem padding-bottom-1rem'>
                <p className='text-sm medium color-gray-700 mw768-text-md text-transform-uppercase'>
                    <FormattedMessage defaultMessage='Payment history'/>
                </p>
            </div>
            {!history.length &&
                <div className='padding-top-2rem text-align-center'>
                    <PaymentsHistoryEmpty className='width-9dot5rem'/>
                    <p className='padding-top-1rem text-md medium color-gray-900'>
                        <FormattedMessage defaultMessage='No payments are available'/>
                    </p>
                    <p className='padding-top-0dot25rem text-sm color-gray-500'>
                        <FormattedMessage defaultMessage='You have not made any subscription payments yet'/>
                    </p>
                </div>
            }
            {history &&
                <>
                    <div className='mw768-max-width-26dot5rem padding-top-1dot5rem mw768-padding-top-2dot75rem payment-history position-relative grid-container padding-bottom-1dot5rem mw768-padding-bottom-2dot75rem'>
                        {history.map((item, index) => (
                            <PaymentsHistoryRow key={index} {...{item: item.node, updateHistory: refreshQueryOptions}}/>
                        ))}
                    </div>
                    <div className=''>
                        {hasNext &&
                            <Button {...{size: '2xl', plain: true, color: 'pale', clickHandler: () => loadNext(3), disabled: isLoadingNext}}>
                                <ChevronDownIcon className='display-block height-1dot25rem width-1dot25rem margin-right-0dot5rem'/>
                                <FormattedMessage defaultMessage='More'/>
                            </Button>
                        }
                        {/* {
                            <Button {...{size: '2xl', plain: true, color: 'pale', clickHandler: showHistoryItems}}>
                                <ChevronUpIcon className='display-block height-1dot25rem width-1dot25rem margin-right-0dot5rem'/>
                                <FormattedMessage defaultMessage='Hide'/>
                            </Button>
                        } */}
                    </div>
                </>
            }
        </div>
    )
}

export default React.memo(PaymentHistory);