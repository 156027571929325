import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {SubscriptionStatusContext} from '../contexts/Subscription';
import useSubscription from '../useSubscription';
import {graphqlWhoamiNextQuery} from '../graphql';

const Watcher = ({refresh}) => {
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    const payload = React.useMemo(() => ({
        channel: `/merchant/${whoamiNext.id}/poolticks`,
        onNext: () => refresh(),
        onError: (error) => {}
    }), [refresh]);
    useSubscription(payload);
    const subscriptionStatus = React.useContext(SubscriptionStatusContext);
    React.useEffect(() => {
        if (subscriptionStatus === 0)
            refresh();
    }, [subscriptionStatus, refresh]);
    return null;
};

export default React.memo(Watcher);