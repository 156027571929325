import React, {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import {queryParameters} from '../utils/qs';
import searchUnFilter from '../utils/searchUnFilter';
import Skeleton from './Skeleton';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;

const LogOutRedirect = () => {
    const location = useLocation();
    const locationRef = useRef();
    locationRef.current = location;
    useEffect(() => {
        const qps = queryParameters(locationRef.current);
        setTimeout(() => window.location.href = RAZZLE_APP_ACCOUNTS + '/scope?scope=Users:.* Recurrings:.*&redirect=' + document.location.origin + '/callback&continue='
            + encodeURIComponent(location.pathname + searchUnFilter(document.location.search))
            + (qps.length ? '&' + qps.join('&') : ''),
            500
        );
    }, [locationRef]);
    
    return (
        <Skeleton/>
    );
}

export default React.memo(LogOutRedirect);
