import React from 'react';
import LogOutRedirect from './LogOutRedirect';
import {useAuthorizationState} from '../contexts/Authorization';

const Private = ({children}) => {
    const authorizationState = useAuthorizationState();
    return (
        <>{authorizationState ? <>{children}</> : <>{<LogOutRedirect/>}</>}</>
    );
}

export default React.memo(Private);