/**
 * @generated SignedSource<<a3b0628fc79e93dc810f784df1073362>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "serviceId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "serviceId"
      }
    ],
    "concreteType": "Service",
    "kind": "LinkedField",
    "name": "serviceById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "demo",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mrchntd",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameUk",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "graphqlServiceByIdQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "graphqlServiceByIdQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "836ebd0961ff26a9407f3556c70421eb",
    "id": null,
    "metadata": {},
    "name": "graphqlServiceByIdQuery",
    "operationKind": "query",
    "text": "query graphqlServiceByIdQuery(\n  $serviceId: String\n) {\n  serviceById(id: $serviceId) {\n    id\n    demo\n    mrchntd\n    name\n    nameUk\n  }\n}\n"
  }
};
})();

node.hash = "2d5acf7e92e24a5ce3f5b73afa378848";

module.exports = node;
