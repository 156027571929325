import React from 'react';
import {Outlet, useParams, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import ArrowLeft from '../assets/arrow-left.svg';
import logoUAF from '../assets/logo-uaf.png';
import LogoOttryFooterText from '../assets/logo-ottry-footer-text.svg';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import i18n from '../utils/i18n';
import useQuery from '../utils/useQuery';
import {FetchKeyStateContext} from '../contexts/FetchKey';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from './Skeleton';
import Fallback from './Fallback';
import {useAuthorizationState} from '../contexts/Authorization';
import Redirect from './Redirect';
import searchFilter from '../utils/searchFilter';
import classNames from 'classnames';
import {graphqlServiceByIdQuery} from '../graphql';

const RAZZLE_APP_LEGAL = process.env.RAZZLE_APP_LEGAL;
const RAZZLE_APP_RETURN_WEBSITE = process.env.RAZZLE_APP_RETURN_WEBSITE;
const RAZZLE_APP_SERVICE_ID = process.env.RAZZLE_APP_SERVICE_ID;
const RAZZLE_APP_ENDPOINT_ID = process.env.RAZZLE_APP_ENDPOINT_ID;
const ENDPOINT_LINK = `/service/${RAZZLE_APP_SERVICE_ID}/endpoint/${RAZZLE_APP_ENDPOINT_ID}`;

const ServiceName = React.memo(({serviceId}) => {
    const {serviceById} = useLazyLoadQuery(graphqlServiceByIdQuery, {serviceId: serviceId || RAZZLE_APP_SERVICE_ID});
    const {locale = 'uk'} = useQuery();
    return (
        <p className='color-gray-25 text-md mw768-flex-order-2 mw768-padding-bottom-2rem mw768-display-xs'>
            {i18n(serviceById, 'name', locale)}
        </p>
    )
});

const Shell = React.memo(({serviceId}) => {
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    const [dropDownVisible, setDropDownVisibility] = React.useState(false);
    const buttonRef = React.useRef();
    React.useEffect(() => {
        const onClickOutsideHandler = (event) => {
            if (dropDownVisible && !(
                buttonRef.current.contains(event.target)
            )) setDropDownVisibility(false);
        };
        document.addEventListener('click', onClickOutsideHandler, true);
        return () => {
            document.removeEventListener('click', onClickOutsideHandler, true);
        };
    }, [dropDownVisible, buttonRef]);
    return (
        <div>
            <div className='display-flex flex-direction-column mw768-flex-direction-row min-height-100vh'>
                <header className='background-color-gray-800 padding-top-2rem padding-bottom-2rem padding-left-1dot5rem padding-right-1dot5rem mw768-padding-4rem mw768-max-width-33rem mw768-display-flex mw768-flex-direction-column mw768-flex-basis-35percent'>
                    <div className='padding-bottom-1dot25rem mw768-flex-order-3 mw768-padding-bottom-0 mw768-flex-1 color-gray-50 display-flex justify-content-space-between'>
                        <a href={RAZZLE_APP_RETURN_WEBSITE} className='display-flex text-sm mw768-text-md'>
                            <ArrowLeft className='display-block height-1dot25rem width-1dot25rem margin-right-0dot5rem'/>
                            <FormattedMessage defaultMessage='Return to UAF website'/>
                        </a>
                        <div className='mw768-display-none position-relative'>
                            <div className='color-gray-50 text-sm medium' ref={buttonRef}>
                                <p className={classNames('padding-top-0dot125rem padding-bottom-0dot125rem padding-left-0dot625rem padding-right-0dot625rem border-radius-1rem cursor-pointer', {'background-color-gray-900': dropDownVisible})} onClick={() => setDropDownVisibility(true)}>
                                    {locale === 'uk' ? 'UA' : 'EN'}
                                </p>
                            </div>
                            <div className={classNames('position-absolute display-none background-color-white text-sm color-gray-700 border-radius-0dot5rem shadow-lg top-1dot75rem trasnform-x-center', {'display-block': dropDownVisible})}>
                                <div className='padding-top-0dot625rem padding-bottom-0dot625rem padding-left-1rem padding-right-1rem'>
                                    <a
                                        href={`${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`}
                                    >UA</a>
                                </div>
                                <div className='padding-top-0dot625rem padding-bottom-0dot625rem padding-left-1rem padding-right-1rem'>
                                    <a
                                        href={`${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`}
                                    >EN</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='padding-bottom-1dot5rem mw768-flex-order-1 mw768-padding-bottom-4rem'>
                        <img className='display-block height-3rem mw768-height-4rem' src={logoUAF}/>
                    </div>
                    <ServiceName {...{serviceId}}/>
                    <div className='display-none mw768-display-flex flex-order-4 color-gray-100 justify-content-space-between'>
                        <a href='https://ottry.com' target='_blank' className='text-sm medium display-flex align-items-center'>
                            <FormattedMessage defaultMessage='Powered by'/>
                            <LogoOttryFooterText className='margin-left-0dot5rem height-1rem'/>
                        </a>
                        <div className='display-flex color-gray-400'>
                        <span className={classNames('text-sm medium margin-right-0dot5rem', {'color-white': locale === 'uk'})}>
                                <a
                                    href={`${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`}
                                >UA</a>
                        </span>
                            <span className={classNames('text-sm medium margin-right-0dot5rem', {'color-white': locale === 'en'})}>
                                <a
                                    href={`${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`}
                                >EN</a>
                            </span>
                        </div>
                    </div>
                    <div className='display-none-flex flex-order-5 padding-top-1rem flex-wrap-wrap'>
                        <div className='text-md color-gray-400 margin-right-1rem'>
                            <a href={RAZZLE_APP_LEGAL + `/user-agreement-flau-${locale}.docx`} download><FormattedMessage defaultMessage='Terms of use'/></a>
                        </div>
                        <div className='text-md color-gray-400 margin-right-1rem'>
                            <a href={RAZZLE_APP_LEGAL + `/privacy-policy-flau-${locale}.docx`} download><FormattedMessage defaultMessage='Privacy policy'/></a>
                        </div>
                        <div className='text-md color-gray-400'>
                            <a href={RAZZLE_APP_LEGAL + `/cookies-flau-${locale}.docx`} download>Cookies</a>
                        </div>
                    </div>
                </header>
                <div className='flex-1 mw768-flex-auto mw768-flex-basis-65percent'>
                    <React.Suspense fallback={<Skeleton/>}>
                        <ErrorBoundary FallbackComponent={Fallback}>
                            <Outlet/>
                        </ErrorBoundary>
                    </React.Suspense>
                </div>
                <footer className='mw768-display-none display-block color-gray-500 text-xs medium padding-top-2rem padding-bottom-1dot5rem'>
                    <a href='https://ottry.com' className='display-flex justify-content-center' target='_blank'>
                        <FormattedMessage defaultMessage='Powered by'/>
                        <LogoOttryFooterText className='margin-left-0dot5rem width-2dot25rem'/>
                    </a>
                </footer>
            </div>
            <div className='display-flex-none padding-top-1rem padding-bottom-1dot5rem margin-right-1dot5rem margin-left-1dot5rem border-top-1px-solid border-color-gray-200'>
                <div className='text-md color-gray-400 margin-right-1rem'>
                    <a href={RAZZLE_APP_LEGAL + `/user-agreement-flau-${locale}.docx`} download><FormattedMessage defaultMessage='Terms of use'/></a>
                </div>
                <div className='text-md color-gray-400 margin-right-1rem'>
                    <a href={RAZZLE_APP_LEGAL + `/privacy-policy-flau-${locale}.docx`} download><FormattedMessage defaultMessage='Privacy policy'/></a>
                </div>
                <div className='text-md color-gray-400'>
                    <a href={RAZZLE_APP_LEGAL + `/cookies-flau-${locale}.docx`} download>Cookies</a>
                </div>
            </div>
        </div>
    )
});

export const RecurringShell = React.memo(() => {
    const {recurringId} = useParams();
    const fetchKeyState = React.useContext(FetchKeyStateContext);
    const {viewer} = useLazyLoadQuery(
        graphql`
            query ShellRecurringQuery($first: Int!, $after: String) {
                viewer {
                    recurringsByViewer(first: $first, after: $after) @connection(key: "Recurrings_recurringsByViewer") {
                        edges {
                            node {
                                id
                                recurringEndpoints {
                                    endpoint {
                                        id
                                    }
                                }
                                recurringService {
                                    service {
                                        id
                                    }
                                }   
                            }
                        }
                    }
                }
            }
        `,
        {
            first: 100,
        },
        {fetchKey: fetchKeyState}
    );
    const findRecurringById = (item) => item.node.id === recurringId;
    const recurring = viewer ? viewer.recurringsByViewer.edges.find(findRecurringById) : null;
    if (!recurring) {
        return <Redirect to={ENDPOINT_LINK}/>
    }
    return (
        <Shell {...{serviceId: recurring.node.recurringService.service.id}}/>
    )
});

export const EndpointShell = React.memo(() => {
    const {serviceId, endpointId} = useParams();
    const authorizationState = useAuthorizationState();
    const fetchKeyState = React.useContext(FetchKeyStateContext);
    if (authorizationState) {
        const {viewer} = useLazyLoadQuery(
            graphql`
                query ShellCheckRecurringQuery($first: Int!, $after: String) {
                    viewer {
                        recurringsByViewer(first: $first, after: $after) @connection(key: "Recurrings_recurringsByViewer") {
                            edges {
                                node {
                                    id
                                    recurringEndpoints {
                                        endpoint {
                                            id
                                        }
                                    }
                                    recurringService {
                                        service {
                                            id
                                        }
                                    }   
                                }
                            }
                        }
                    }
                }
            `,
            {
                first: 100
            },
            {fetchKey: fetchKeyState}
        );
        const findRecurringByService = (item) => item.node.recurringService.service.id == serviceId && item.node.recurringEndpoints.some(e => e.endpoint.id == endpointId);
        const recurring = viewer ? viewer.recurringsByViewer.edges.find(findRecurringByService) : null;
        if (recurring) {
            return <Redirect to={`/recurring/${recurring.node.id}`}/>
        }
    }

    return (
        <Shell {...{serviceId}}/>
    )  
});

export default Shell;