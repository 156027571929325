import React from 'react';
import {SubscriptionContext, SubscriptionStatusContext} from './Subscription';

export default React.memo(({children, subscription}) => {
    const [subscriptionStatus, setSubscriptionStatus] = React.useState();
    React.useEffect(() => subscription.onConnected(() => setSubscriptionStatus(0)), [subscription, setSubscriptionStatus]);
    React.useEffect(() => subscription.onDisconnected(() => setSubscriptionStatus()), [subscription, setSubscriptionStatus]);
    React.useEffect(() => subscription.onError((e) => console.log(e)), [subscription]);
    return (
        <SubscriptionContext.Provider value={subscription}>
            <SubscriptionStatusContext.Provider value={subscriptionStatus}>
                {children}
            </SubscriptionStatusContext.Provider>
        </SubscriptionContext.Provider>
    );
});