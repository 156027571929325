import React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import searchFilter from '../utils/searchFilter';
import {accessToken__, refreshToken__, tokenType__, useAuthorizationDispatch, useAuthorizationStore} from '../contexts/Authorization';
import Skeleton from './Skeleton';

// const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;
// const RAZZLE_APP_SIGNOUT = process.env.RAZZLE_APP_SIGNOUT;

export default React.memo(() => {
    const authorizationDispatch = useAuthorizationDispatch();
    const authorizationStore = useAuthorizationStore();
    const location = useLocation();
    const locationRef = React.useRef();
    locationRef.current = location;
    const navigate = useNavigate();
    const navigateRef = React.useRef();
    navigateRef.current = navigate;
    React.useEffect(() => {
        authorizationStore.remove(tokenType__);
        authorizationStore.remove(accessToken__);
        authorizationStore.remove(refreshToken__);
        authorizationDispatch();
        navigateRef.current(`/${searchFilter(locationRef.current.search)}`, {replace: true});
        // setTimeout(
        //     () => window.location.href =
        //         RAZZLE_APP_ACCOUNTS + '/' + RAZZLE_APP_SIGNOUT + '.html?cache=0&continue='
        //         + encodeURIComponent(document.location.origin + document.location.search),
        //     500
        // );
    }, []);
    return (
        <Skeleton/>
    );
});